import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Bouesque from 'core/components/Bouesque';
import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import YandexMetrikaGoalSender from 'core/components/MetricsEventSender/YandexMetrikaGoalSender';
import SelfPromo from 'core/components/SelfPromo';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import GridPosition from 'core/components/GridPosition';

import Prediction from 'site/components/Prediction';
import { IndentWrap } from 'site/components/Wrappers';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';
import { Smi2Vertical } from 'site/components/Partners';
import CompatibilityWidget from 'site/components/CompatibilityWidget';

import {
  AdPlace2,
  AdPlace3,
  Parallax,
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
} from 'site/components/Ads/mobile';

import Main from './Main';
import NewsAndPopular from './NewsAndPopular';
import GridRenderer from './GridRenderer';
import NekrologAndVyyasnili from './NekrologAndVyyasnili';

import CardExpert from 'site/cards/CardExpert';
import { Card3Type4, Card3Type1 } from 'site/cards/Card3';
import { Card4S } from 'site/cards/Card4';

import { MAIN_BLOCK_LIMIT } from 'site/constants';

const RIGHT_COLUMN_INDENT = 20;

function MainPageContent(props) {
  const {
    mainTopics,
    news,
    popular,
    isMobile,
    nekrolog,
    lyudiTakie,
    vyyasnili,
    eksklyuzivy,
    tolkoZvezdy,
  } = props;

  const indent = isMobile ? 30 : 28;
  const sideIndent = isMobile ? 15 : 0;

  const mainNewsLimit = MAIN_BLOCK_LIMIT - mainTopics.length;
  const mainNews = news.slice(0, mainNewsLimit);
  const newsFeed = news.slice(mainNewsLimit);

  return (
    <AdWrapper bottom={indent}>
      <ColumnLayout
        rightColumn={(
          <Fragment>
            <Bouesque
              height='42px'
              radius='6px'
              withoutIcon
            />
            <IndentWrap bottom={RIGHT_COLUMN_INDENT} />
            <AdPlace2 />
            <SelfPromo />
            <IndentWrap bottom={RIGHT_COLUMN_INDENT} />
            <Smi2Vertical />
            <IndentWrap bottom={RIGHT_COLUMN_INDENT} />
            <AdPlace3 />
          </Fragment>
        )}
      >
        <IndentWrap
          bottom={indent}
          left={sideIndent}
        >
          <Main content={mainTopics} news={mainNews} />
        </IndentWrap>

        <IndentWrap
          bottom={indent}
          left={sideIndent}
          right={sideIndent}
        >
          <Prediction />
        </IndentWrap>

        <Desktop><SuperFooter /></Desktop>
        <Mobile><Listing1 /></Mobile>

        <IndentWrap bottom={indent}>
          <NewsAndPopular news={newsFeed} popular={popular} />
        </IndentWrap>
      </ColumnLayout>

      <Desktop><Parallax /></Desktop>

      <ColumnLayout
        rightColumn={(
          <Fragment>
            <YandexMetrikaGoalSender click='name_compatibility_click' show='name_compatibility_show'>
              <CompatibilityWidget side />
            </YandexMetrikaGoalSender>
            <IndentWrap bottom={indent} />
          </Fragment>
        )}
      >

        <IndentWrap bottom={indent}>
          <GridRenderer
            content={lyudiTakie}
            title='Истории людей'
            card={Card4S}
          />
        </IndentWrap>

        <IndentWrap bottom={indent}>
          <NekrologAndVyyasnili
            content={vyyasnili}
            title='Разбираемся вместе'
            card={Card3Type1}
          />
        </IndentWrap>

        <Mobile>
          <IndentWrap bottom={indent}>
            <GridPosition center>
              <Link to='/news' type='blank'>
                <Button size='small' >
                  Все новости
                </Button>
              </Link>
            </GridPosition>
          </IndentWrap>
        </Mobile>

        <IndentWrap bottom={indent}>
          <GridRenderer
            content={eksklyuzivy}
            card={CardExpert}
            itemWidthMobile={273}
            title='Наша экспертиза'
          />
        </IndentWrap>

        <BreakpointSwitcher
          desktop={<Context />}
          mobile={<Listing2 />}
        />

        <IndentWrap bottom={indent}>
          <GridRenderer
            content={tolkoZvezdy}
            title='От первого лица'
            gridTemplateColumns='1fr 1fr'
          />
        </IndentWrap>

        <BreakpointSwitcher
          desktop={<Context count={2} />}
          mobile={<Listing3 />}
        />

        <NekrologAndVyyasnili
          content={nekrolog}
          title='RIP'
          card={Card3Type4}
        />

      </ColumnLayout>
    </AdWrapper>
  );
}

MainPageContent.propTypes = {
  mainTopics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  news: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  popular: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  nekrolog: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  lyudiTakie: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  vyyasnili: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  eksklyuzivy: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  tolkoZvezdy: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPageContent);
