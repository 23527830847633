import PropTypes from 'prop-types';

import RecommendationWidget from 'core/components/RecommendationWidget';

import { withBreakpoint } from 'core/components/breakpoint';

import Title2 from 'site/components/Title2';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '3fd5de50a2e6482192c94f8be8c63b7e' : '5b61f05766ad4598ba36678edde24572'}
      title='Популярное'
      headerComponent={Title2}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
