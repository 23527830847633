import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Mobile, Desktop } from 'core/components/breakpoint';
import Drum from 'core/components/Drum';
import GridFeed from 'core/components/GridFeed';

import BackTitle from 'site/components/BackTitle';
import { IndentWrap } from 'site/components/Wrappers';

import Card4 from 'site/cards/Card4';


export default function GridRenderer(props) {
  const {
    content,
    title,
    gridTemplateColumns,
    card: Card,
    itemWidthMobile,
  } = props;

  if (!content?.length) return null;

  return (
    <BackTitle title={title}>
      <Desktop>
        <GridFeed
          content={content}
          gridTemplateColumns={gridTemplateColumns}
          gap='28px'
          card={Card}
        />
      </Desktop>
      <Mobile>
        <IndentWrap left={15}>
          <Drum
            content={content}
            card={Card}
            itemWidthMobile={itemWidthMobile}
            spaceBetween={5}
          />
        </IndentWrap>
      </Mobile>
    </BackTitle>
  );
}

GridRenderer.defaultProps = {
  gridTemplateColumns: '1fr 1fr 1fr',
  card: Card4,
  itemWidthMobile: 305,
};

GridRenderer.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  title: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  card: PropTypes.func,
  itemWidthMobile: PropTypes.number,
};
