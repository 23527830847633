import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import withTabs from 'core/components/withTabs';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import AdWrapper from 'core/components/Ad/AdWrapper';
import PopularCore from 'core/components/Popular';

import { ListingSpecNewsNative } from 'site/components/Ads/mobile';

import { Card1Type1 } from 'site/cards/Card1';

import { TODAY_PERIOD } from 'site/constants';

import styles from './index.styl';


const tabs = [
  {
    title: 'Сегодня',
    days_period: TODAY_PERIOD,
  }, {
    title: 'За неделю',
    days_period: '7',
  },
].map((t, i) => ({
  ...t,
  radius: '30px',
  size: 'small2',
  className: !i ? styles.button : null,
}));

const include = filterRequiredParams([Card1Type1], 'include');
const fields = filterRequiredParams([Card1Type1], 'fields');

function PopularComponent({ content, activeIndex, days_period: daysPeriod, isMobile }) {
  /**
   * Для первой вкладки получаем топики на уровне страницы,
   * уже на клиенте получаем для других вкладок при переключении.
   */
  const contentProps = activeIndex === 0
    ? { topics: content }
    : {
      sort: '-views_count',
      limit: 9,
      days_period: daysPeriod,
      include,
      fields,
    };

  return (
    <PopularCore
      card={Card1Type1}
      interitemSpacing={isMobile ? 20 : 30}
      {...contentProps}
    />
  );
}

const Popular = withBreakpoint(PopularComponent);

PopularComponent.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.number,
  days_period: PropTypes.string,
  isMobile: PropTypes.bool,
};


function TabbedPopular({ content, theme, isMobile }) {
  const PopularWithTabs =  withTabs({
    tabs,
    justifyContent: 'center',
    indent: isMobile ? 19 : 29,
    activeButtonType: isMobile ? 'black' : 'primary',
    inactiveButtonType: isMobile ? 'light' : 'ghost',
    isMobile,
  })(Popular);

  return (
    <div className={styles.tabbedPopular}>
      <style jsx>{`
        .${styles.tabbedPopular}
          background ${theme.colors.white}
          :global(.mobile) &
            background ${theme.colors.grey3}
        .${styles.title}
          font-family ${theme.fonts.display}
      `}</style>
      <div className={styles.title}>Популярное</div>
      <PopularWithTabs content={content} />
      <Mobile>
        <AdWrapper top={20}>
          <ListingSpecNewsNative />
        </AdWrapper>
      </Mobile>
    </div>
  );
}

TabbedPopular.propTypes = {
  content: PropTypes.array,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(TabbedPopular));
