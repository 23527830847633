import { Route } from 'core/libs/router';
import PropTypes from 'prop-types';

import configResolver from './shared-app-config';
import { SOVMESTIMOST_LINK } from './constants';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import bindPropsHOC from 'core/components/bindProps';
import RulesRecommenders from 'core/components/RulesRecommenders';

import GoogleDBM from 'site/components/GoogleDBM';
import Layout from 'site/components/Layout';

import Main from 'site/pages/main';
import Search from 'site/pages/search';
import Rubric from 'site/pages/rubric';
import Encyclopedy from 'site/pages/encyclopedy';
import Legal from 'site/pages/legal';
import Tag from 'site/pages/tag';
import Topic from 'site/pages/topic';
import Special from 'site/pages/special';
import Compatibility from 'site/pages/compatibility/';
import OneName from 'site/pages/compatibility/OneName';
import About from 'site/pages/about';
import Authors from 'site/pages/Authors';
import Author from 'site/pages/Author';
import Comments from 'site/pages/comments';
import Profile from 'site/pages/profile';
import LeaderBoard from 'site/pages/leaderboard';


const encyclopedyRoutes = [
  '/beauty/enciklopediya-krasoty',
  '/career/enciklopediya-karery',
  '/career/enciklopediya-etiketa',
  '/diet/enciklopediya-pohudeniya',
  '/health/enciklopediya-zdorovogo-obraza-zhizni',
  '/hor/magiya-kamney-ya',
  '/psy/enciklopediya-seksa',
  '/psy/enciklopediya-prava',
  '/style/modnyy-slovar',
  '/food/kulinarnyy-spravochnik',
  '/home/enciklopediya-cvetov',
  '/mama/enciklopediya-beremennosti',
].map((path, i) => (
  <Route
    key={i}
    path={path}
    component={Encyclopedy}
    exact
  />
));

function Site(props, context) {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver(context.requestHeaders);

  return (
    <App {...config}>
      <GoogleDBM />
      <Layout>
        <BaseRoutes
          main={Main}
          search={Search}
          rubrics={Rubric}
          topic={Topic}
          tags={Tag}
          about={About}
          authors={Authors}
          author={Author}
          comments={Comments}
          profile={Profile}
          legal={Legal}
          leaderBoard={LeaderBoard}
          rulesRecommenders={bindPropsHOC({ mail: 'info@passion.su' })(RulesRecommenders)}
        >
          {encyclopedyRoutes}
          <Route
            exact
            path='/special'
            component={Special}
          />
          <Route
            exact
            path={SOVMESTIMOST_LINK}
            component={Compatibility}
          />
          <Route
            path={`${SOVMESTIMOST_LINK}/:sex/:name`}
            component={OneName}
          />
        </BaseRoutes>
      </Layout>
    </App>
  );
}

Site.contextTypes = {
  requestHeaders: PropTypes.shape({
    host: PropTypes.string,
  }),
};

export default Site;
