import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import resolveRelationships from 'core/utils/relationships';

import { cardDateFormat } from 'site/utils';
import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card3(props) {
  const {
    type,
    content,
    isMobile,
    theme,
  } = props;

  const {
    showTime,
    showBackground,
    imageSize = 90,
    imageRatio,
    borderRadius,
    rowReverse,
    showImage,
  } = mapTypes[type] || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: published,
  } = content.attributes;

  const {
    image: {
      versions,
    },
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
  } = content.extra || {};

  const coverSize = isMobile ? 60 : imageSize;

  return (
    <div className={cx(
      styles.card3,
      showBackground && styles._showBackground,
      showImage && styles._showImage)}
    >
      <style jsx>{`
        .${styles.card3}
          border-radius ${borderRadius || 0}px
          &.${styles._showBackground}
            background-color ${theme.colors.card}
            :global(.mobile) &
              background-color ${theme.colors.grey3}
          &:hover
            .${styles.headline}
              color ${theme.colors.active1}

        .${styles.time}
          font 400 14px/16px ${theme.fonts.display}
          color ${theme.colors.active1}

        .${styles.image}
          aspect-ratio ${imageRatio}
          max-width ${coverSize}px
          &:after
            transition opacity ${theme.animations.hover}

        .${styles.headline}
          color ${theme.colors.black}
          font 400 18px/23px ${theme.fonts.display}
          :global(.mobile) &
            font 400 16px/20px ${theme.fonts.display}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={cx(styles.link, rowReverse && styles._reversed)}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {showImage && (
          <div className={styles.image}>
            <SmartImage
              versions={versions}
              maxWidth={coverSize}
              aspectRatio={imageRatio}
            />
          </div>
        )}
        <div className={styles.texts}>
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
          {showTime && (
            <div className={styles.time}>
              {cardDateFormat(published)}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

Card3.defaultProps = {
  type: 0,
};

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

const Card = cardHOC(Card3);

export const Card3Type1 = bindProps({ type: 1 })(Card);
export const Card3Type2 = bindProps({ type: 2 })(Card);
export const Card3Type3 = bindProps({ type: 3 })(Card);
export const Card3Type4 = bindProps({ type: 4 })(Card);

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card3 as StorybookComponent };
