import Lora from './Lora/Lora-Regular.woff';
import Lora2 from './Lora/Lora-Regular.woff2';
import LoraBoldItalic from './Lora/Lora-BoldItalic.woff';
import LoraBoldItalic2 from './Lora/Lora-BoldItalic.woff2';
import Ubuntu from './Ubuntu/Ubuntu-Regular.woff';
import Ubuntu2 from './Ubuntu/Ubuntu-Regular.woff2';
import UbuntuMedium from './Ubuntu/Ubuntu-Medium.woff';
import UbuntuMedium2 from './Ubuntu/Ubuntu-Medium.woff2';
import UbuntuBold from './Ubuntu/Ubuntu-Bold.woff';
import UbuntuBold2 from './Ubuntu/Ubuntu-Bold.woff2';
import UbuntuBoldItalic from './Ubuntu/Ubuntu-BoldItalic.woff';
import UbuntuBoldItalic2 from './Ubuntu/Ubuntu-BoldItalic.woff2';
import UbuntuItalic from './Ubuntu/Ubuntu-Italic.woff';
import UbuntuItalic2 from './Ubuntu/Ubuntu-Italic.woff2';
import MontserratBlack from './Montserrat/Montserrat-Black.woff';
import MontserratBlack2 from './Montserrat/Montserrat-Black.woff2';
import OswaldMedium from './Oswald/Oswald-Medium.woff';
import OswaldMedium2 from './Oswald/Oswald-Medium.woff2';
import PtSans from './PtSans/PtSans-regular.woff';
import PtSans2 from './PtSans/PtSans-regular.woff2';
import PtSansBold from './PtSans/PtSans-bold.woff';
import PtSansBold2 from './PtSans/PtSans-bold.woff2';
import PhonkSansBold from './PhonkSans/PhonkSans-Bold.woff';
import PhonkSansBold2 from './PhonkSans/PhonkSans-Bold.woff2';


const fonts = {
  display: 'Ubuntu, Ubuntu-fallback, serif',
  display2: 'Montserrat, sans-serif',
  display3: 'PhonkSans, sans-serif',
  text: 'Lora, Lora-fallback, serif',
  nuance: 'PT Sans, PT Sans-fallback, sans-serif',
  faces: [
    {
      fontFamily: 'Lora',
      src: `local('Lora'), url(${Lora2}) format('woff2'), url(${Lora}) format('woff')`,
    }, {
      fontFamily: 'Lora',
      fontStyle: 'italic',
      fontWeight: 'bold',
      src: `local('Lora-BoldItalic'), url(${LoraBoldItalic2}) format('woff2'), url(${LoraBoldItalic}) format('woff')`,
    }, {
      fontFamily: 'Ubuntu',
      src: `local('Ubuntu'), url(${Ubuntu2}) format('woff2'), url(${Ubuntu}) format('woff')`,
    }, {
      fontFamily: 'Ubuntu',
      fontWeight: 500,
      src: `local('Ubuntu-Medium'), url(${UbuntuMedium2}) format('woff2'), url(${UbuntuMedium}) format('woff')`,
    }, {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
      src: `local('Ubuntu-Bold'), url(${UbuntuBold2}) format('woff2'), url(${UbuntuBold}) format('woff')`,
    }, {
      fontFamily: 'Ubuntu',
      fontStyle: 'italic',
      src: `local('Ubuntu-Italic'), url(${UbuntuItalic2}) format('woff2'), url(${UbuntuItalic}) format('woff')`,
    }, {
      fontFamily: 'Ubuntu',
      fontStyle: 'italic',
      fontWeight: 700,
      src: `local('Ubuntu-BoldItalic'), url(${UbuntuBoldItalic2}) format('woff2'), url(${UbuntuBoldItalic}) format('woff')`,
    }, {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      src: `local('Montserrat-Black'), url(${MontserratBlack2}) format('woff2'), url(${MontserratBlack}) format('woff')`,
    }, {
      fontFamily: 'Oswald',
      fontWeight: 500,
      src: `local('Oswald-Medium'), url(${OswaldMedium2}) format('woff2'), url(${OswaldMedium}) format('woff')`,
    }, {
      fontFamily: 'PT Sans',
      src: `local('PtSans'), url(${PtSans2}) format('woff2'),  url(${PtSans}) format('woff')`,
    }, {
      fontFamily: 'PT Sans',
      fontWeight: 'bold',
      src: `local('PtSans-Bold'), url(${PtSansBold2}) format('woff2'), url(${PtSansBold}) format('woff')`,
    },
    {
      fontFamily: 'PT Sans-fallback',
      sizeAdjust: '96.47%',
      ascentOverride: '110%',
      src: 'local("Arial")',
    }, {
      fontFamily: 'Lora-fallback',
      sizeAdjust: '110.48999999999997%',
      ascentOverride: '90%',
      src: 'local("Times New Roman")',
    }, {
      fontFamily: 'Ubuntu-fallback',
      sizeAdjust: '111.20000000000005%',
      ascentOverride: '94%',
      src: 'local("Times New Roman")',
    }, {
      fontFamily: 'PhonkSans',
      fontWeight: 'bold',
      src: `url(${PhonkSansBold2}) format('woff2'), url(${PhonkSansBold}) format('woff')`,
    },
  ],
};

export default fonts;
