import { compose } from 'core/libs/recompose';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import Card1 from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import CardExpert from 'site/cards/CardExpert';

import { MAIN_BLOCK_LIMIT, TODAY_PERIOD } from 'site/constants';

const mainTopicsProvider = resolve({
  mainTopics: ({ bebopApi, renderError }) => {
    return bebopApi
      .getTopics({
        limit: MAIN_BLOCK_LIMIT,
        list: 'main',
        sort: 'list',
        include: filterRequiredParams([Card4], 'include'),
        fields: filterRequiredParams([Card4], 'fields'),
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(renderError);
  },
});

const dataProvider = resolve({
  news: ({ mainTopics = [], bebopApi, consoleError }) => {
    const newsLimit = 6;
    const limit = newsLimit + MAIN_BLOCK_LIMIT - mainTopics.length;
    const excludedIds = mainTopics.map(item => item.id).join(',');

    return bebopApi
      .getTopics({
        include: filterRequiredParams([Card3, Card4, Card5], 'include'),
        fields: filterRequiredParams([Card3, Card4, Card5], 'fields'),
        excluded_ids: excludedIds,
        topic_type: 'news',
        sort: '-published_at',
        visibility: 'main_page',
        limit,
      })
      .then(denormalizeData)
      .catch(() => consoleError('news block', []));
  },

  popular: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 9,
        include: filterRequiredParams([Card1], 'include'),
        fields: filterRequiredParams([Card1], 'fields'),
        sort: '-views_count',
        days_period: TODAY_PERIOD,
      })
      .then(denormalizeData)
      .catch(() => consoleError('popular block', []));
  },

  nekrolog: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 5 : 10,
        include: filterRequiredParams([Card3], 'include'),
        fields: filterRequiredParams([Card3], 'fields'),
        rubric: 'nekrolog',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('nekrolog', []));
  },

  lyudiTakie: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 5 : 3,
        include: filterRequiredParams([Card4], 'include'),
        fields: filterRequiredParams([Card4], 'fields'),
        rubric: 'lyudi-takie',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('lyudiTakie block', []));
  },

  vyyasnili: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 5 : 6,
        include: filterRequiredParams([Card3], 'include'),
        fields: filterRequiredParams([Card3], 'fields'),
        rubric: 'vyyasnili',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('vyyasnili block', []));
  },

  eksklyuzivy: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 5 : 3,
        include: filterRequiredParams([CardExpert], 'include'),
        fields: filterRequiredParams([CardExpert], 'fields'),
        rubric: 'eksklyuzivy',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('eksklyuzivy', []));
  },

  tolkoZvezdy: ({ bebopApi, consoleError, isMobile }) => {
    return bebopApi
      .getTopics({
        limit: isMobile ? 5 : 2,
        include: filterRequiredParams([Card4], 'include'),
        fields: filterRequiredParams([Card4], 'fields'),
        rubric: 'tolko-zvezdy',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('tolko-zvezdy', []));
  },
});

export default compose(mainTopicsProvider, dataProvider);
