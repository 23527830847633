import PropTypes from 'prop-types';

/**
 * Логотип делаем так, чтобы жирный svg код не собирался в js
 * и не вставлялся в HTML
 */
export default function Logo(props, context) {
  const { amp } = context;

  const {
    type, // eslint-disable-line
    ...otherProps
  } = props;

  const imageAttributes = {
    src: `/icons/logo-${type}.svg`,
    alt: 'passion.su',
    ...otherProps,
  };

  return amp
    ? <amp-img {...imageAttributes} />
    : <img {...imageAttributes} />;
}

Logo.contextTypes = {
  amp: PropTypes.bool,
};

Logo.propTypes = {
  type: PropTypes.oneOf([
    'main',
    'mobile',
    'icon',
  ]).isRequired,
};

Logo.defaultProps = {
  type: 'main',
};
