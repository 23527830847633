import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import GridFeed from 'core/components/GridFeed';

import BackTitle from 'site/components/BackTitle';

import styles from './index.styl';


function NekrologAndVyyasnili(props) {
  const {
    content,
    isMobile,
    title,
    card: Card,
  } = props;

  if (!content?.length) return null;

  return (
    <BackTitle title={title}>
      <div className={styles.nekrologAndVyyasnili}>
        <GridFeed
          content={content}
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
          gap={isMobile ? '20px' : '15px 28px'}
          card={Card}
        />
      </div>
    </BackTitle>
  );
}

NekrologAndVyyasnili.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  card: PropTypes.func,
};

export default withBreakpoint(NekrologAndVyyasnili);
