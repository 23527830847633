import PropTypes from 'prop-types';

import Drum from 'core/components/Drum';
import Scroller from 'core/components/Scroller';

import readMore from 'core/components/TopicContent/blocks/readMore';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import Card2 from 'site/cards/Card2';
import { Card3Type2, Card3Type3 } from 'site/cards/Card3';

import styles from './index.styl';


export default function ReadMore(props) {
  const {
    block,
    parentProps: {
      theme,
      isMobile,
      isDesktop,
    },
  } = props;

  const content = block?.attributes?.read_more_topics || [];

  if (!content.length) return null;

  if (content.length > 2) {
    const restructuredContent = content.map(topic => ({ ...topic, relationships: { image: { data: topic.attributes.image } } }));
    const contentElement = isDesktop
      ? (
        <Drum
          card={Card2}
          arrowsShift={-10}
          arrowsShiftVertical={80}
          itemWidth='275px'
          content={restructuredContent}
        />)
      : (
        <Scroller
          listClassName={styles.scrollerList}
          card={Card2}
          itemWidthMobile='80%'
        >
          {restructuredContent.map(topic => (
            <div key={topic.id} className={styles.scrollerItem}>
              <Card2 content={topic} />
            </div>
          ))}
        </Scroller>);

    return (
      <div className={styles.readMore}>
        <style jsx>{`
          :global(.desktop) .${styles.readMore}
            border-top 2px solid ${theme.colors.active1}
          :global(.mobile)
            & .${styles.readMore}
              margin-right -${theme.layout.indents.right}px
            & :global(.${styles.scrollerItem}:last-child)
              padding-right ${theme.layout.indents.right}px
          .${styles.title}
            font bold italic 22px/1.2 ${theme.fonts.display}
            color ${theme.colors.active1}
            :global(.mobile) &
              font-size 20px
        `}</style>
        <div className={styles.title}>
          Читайте также
        </div>
        {contentElement}
      </div>
    );
  }

  const breakpointCard = isMobile ? Card3Type2 : Card3Type3;

  return readMore(
    block,
    {
      title: isMobile ? 'Читайте на тему:' : 'Читайте также',
      singleCard: breakpointCard,
      feedCard: breakpointCard,
    }
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
