import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';


import styles from './index.styl';

function BackTitle(props) {
  const {
    title,
    children,
    theme,
  } = props;

  return (
    <Fragment>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display3}
          color ${theme.colors.black}
      `}
      </style>
      <div className={styles.title}>{title}</div>
      {children}
    </Fragment>
  );
}

BackTitle.propTypes = {
  /** Текст заголовка */
  title: PropTypes.string.isRequired,
  /** @ignore */
  theme: PropTypes.object,
};

const BackTitleWithHOCs = withTheme(BackTitle);
BackTitleWithHOCs.displayName = 'BackTitle';

export default BackTitleWithHOCs;

export { BackTitle as StorybookComponent };
