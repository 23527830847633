import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import { Desktop, Mobile } from 'core/components/breakpoint';

import { IndentWrap } from 'site/components/Wrappers';

import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';

import { Native1 } from 'site/components/Ads/desktop';


const feedCard = bindProps({ highPriorityImage: true })(Card4);
const drumCardReplacement = bindProps({ highPriorityImage: true })(Card4);
const INDENT = 28;

export default function Glavnoe({ content, news }) {
  const topics = content.concat(news);
  const first = topics.slice(0, 2);
  const second = topics.slice(2);

  return (
    <Fragment>
      <Desktop>
        <Feed
          heroCard={feedCard}
          content={first}
          card={Card4}
          columns={2}
          interitemSpacing={INDENT}
          grid
        />
        <IndentWrap bottom={INDENT} />
        <Feed
          content={second}
          card={Card5}
          columns={3}
          interitemSpacing={INDENT}
          grid
          cardsReplacement={{
            2: replaceCardWithAd(Native1),
          }}
        />
      </Desktop>
      <Mobile>
        <Drum
          content={topics}
          card={Card4}
          spaceBetween={5}
          itemWidthMobile={305}
          cardsReplacement={{
            0: drumCardReplacement,
          }}
        />
      </Mobile>
    </Fragment>
  );
}

Glavnoe.propTypes = {
  content: PropTypes.array,
  news: PropTypes.array,
};
