import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

function PageWrapper({ children, isMobile }) {
  return (
    <div
      style={{
        padding: `${isMobile ? '15px 0' : '20px 40px'}`,
      }}
    >
      {children}
    </div>
  );
}

PageWrapper.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PageWrapper);
