import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';
import themeSwitcherHOC from 'core/components/themeSwitcher';
import Link from 'core/components/Link';
import Image from 'core/components/Image';
import AdvTooltip from 'core/components/AdvTooltip';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import { cardDateFormat } from 'site/utils';
import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'link',
  'published_at',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

const sizes = {
  s: {
    imageMaxWidth: 522,
    aspectRatio: 1,
  },
  m: {
    imageMaxWidth: 268,
    aspectRatio: 1,
  },
  l: {
    imageMaxWidth: 522,
    aspectRatio: 1.85,
  },
};


function Card5(props) {
  const {
    content,
    theme,
    location,
    position,
    showTime,
    size,
  } = props;

  const {
    headline,
    list_headline: listHeadline,
    link,
    published_at: published,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    imageMaxWidth,
    aspectRatio,
  } = sizes[size] || {};

  const isMainPage = location.pathname === '/';
  const shouldShowAdvTooltip = !!(isMainPage && position === 0 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  return (
    <div className={cx(styles.card, styles[`_size_${size}`])}>
      <style jsx>{`
        .${styles.card}
          max-width ${imageMaxWidth}px
          font-family ${theme.fonts.display}

        .${styles.title}
          color ${theme.colors.primary}

        .${styles.image}
          &:before
          &:after
            transition opacity ${theme.animations.hover}
      `}</style>
      <div className={styles.cardInner}>
        <Link
          type='secondary'
          to={topicLink}
          className={styles.link}
        >
          <div className={styles.image}>
            <Image
              src={cover}
              previewSrc={previewCover}
              maxWidth={imageMaxWidth}
              aspectRatio={aspectRatio}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>{listHeadline || headline}</div>
            {showTime && (
              <div className={styles.time}>
                {cardDateFormat(published)}
              </div>
            )}
          </div>
        </Link>
      </div>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
        />
      )}
    </div>
  );
}

Card5.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
  showTime: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

Card5.defaultProps = {
  imageMaxWidth: 280,
  size: 'm',
};

const Card = themeSwitcherHOC('dark')(withRouter(withTheme(Card5)));

export const Card5L = bindProps({ size: 'l', showTime: true })(Card);
export const Card5S = bindProps({ size: 's', showTime: true })(Card);

Card.displayName = 'Card5';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
