import color from 'core/libs/color';

import deepmerge from 'core/libs/deepmerge';

import { NegativeMobile } from 'core/components/Wrappers';

import Logo from 'site/components/Logo';
import Photo from 'site/icons/Photo';
import PhotoAlt from 'site/icons/PhotoAlt';
import Video from 'site/icons/Video';
import VideoAlt from 'site/icons/VideoAlt';
import Burger from 'site/icons/Burger';
import ArrowRight from 'site/icons/ArrowRight';
import ArrowLeft from 'site/icons/ArrowLeft';
import Close from 'site/icons/Close';
import DefaultAvatar from 'site/icons/DefaultAvatar';

import notFoundImg from 'site/images/notFound.jpg';
import Card from 'site/cards/Card2';
import CompareCard from 'site/cards/CompareCard';

import {
  VERTICAL,
  HORISONTAL,
} from 'site/components/Indents';

import Winner from 'site/icons/Winner.svg';

import fonts from './fonts';


const icons = {
  logo: Logo,
  Video,
  VideoAlt,
  Photo,
  PhotoAlt,
  Burger,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  Close,
  DefaultAvatar,
};

const sizes = {
  extraSmall: {
    font: `14px/18px ${fonts.display}`,
    width: '50px',
    height: '24px',
    padding: '3px 12px',
  },
  small: {
    font: `500 16px/18px ${fonts.display}`,
    width: '50px',
    height: '24px',
    padding: '10px',
  },
  small2: {
    font: `15px/19px ${fonts.display}`,
    width: '50px',
    height: '24px',
    padding: '5px 15px',
  },
  medium: {
    font: `bold 17px/20px ${fonts.display}`,
    width: '100px',
    height: '32px',
    padding: '6px 14px',
  },
  large: {
    font: `bold 20px/22px ${fonts.display}`,
    width: '140px',
    height: '44px',
    padding: '6px 20px 5px',
  },
  extraLarge: {
    font: `bold 20px/22px ${fonts.display}`,
  },
};


export default function baseTheme(atoms) {
  const texts = {
    headline: {
      font: `bold 42px/1.05 ${fonts.display}`,
    },

    title1: {
      font: `bold 42px/1.05 ${fonts.display}`,
    },

    title2: {
      font: `italic 32px/1.25 ${fonts.display}`,
      color: atoms.colors.active1,
    },

    title3: {
      font: `bold italic 22px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    title4: {
      font: `bold 16px/24px ${fonts.display}`,
      color: atoms.colors.hint,
    },

    title5: {
      font: `bold italic 18px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    title6: {
      font: `italic bold 32px/1.3 ${fonts.display}`,
      color: atoms.colors.active1,
    },

    body: {
      font: `18px/26px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    lead: {
      font: `400 20px/29px ${fonts.nuance}`,
      color: color(atoms.colors.primary).alpha(0.8).string(),
      marginTop: '16px',
      letterSpacing: 'normal',
    },

    quote: {
      font: `italic 24px/32px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    caption1: {
      font: `16px/20px ${fonts.nuance}`,
      color: atoms.colors.hint,
    },

    caption2: {
      font: `16px/18px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    caption3: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.placeholder,
    },

    caption4: {
      font: `400 12px/15px ${fonts.display}`,
      color: atoms.colors.grey,
    },
  };

  const navLink = {
    font: `500 16px/22px ${fonts.display}`,
    hover: {
      color: atoms.colors.active1,
    },
  };

  const about = {
    desktop: {
      padding: 0,
    },
    mobile: {
      padding: 0,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 20px/27px ${fonts.display}`,
      },
      info: {
        font: `400 14px/19px ${fonts.display}`,
      },
      link: {
        borderBottom: 'none',
        color: atoms.colors.active1,
        hover: {
          color: atoms.colors.active1,
          borderBottom: `1px ${atoms.colors.active1} solid`,
        },
      },
    },
    gameCompare: {
      readMore: {
        color: atoms.colors.card,
      },
    },
  };

  const shapka = {
    link: {
      padding: '0 30px',
      ...navLink,
    },
    dropdown: {
      more: navLink,
      link: {
        padding: '10px 15px',
        ...navLink,
      },
      marginTop: '-10px',
    },
    height: 110,
    padding: '5px 40px',
    logo: {
      Icon: Logo,
      width: 220,
      height: 75,
    },

    labelNoAds: {
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'none',
    },

    menu: {
      centered: true,
    },

    premiumTopic: {
      link: {
        ...navLink,
        padding: '6px 20px 6px 0',
      },
    },
  };

  const link = {
    tertiary: {
      idle: {
        color: atoms.colors.hint,
      },
      active: {
        color: atoms.colors.active1,
      },
      visited: {
        color: atoms.colors.hint,
      },
    },
    primary: {
      idle: {
        color: atoms.colors.active1,
      },
      hover: {
        color: atoms.colors.primary,
      },
      active: {
        color: color(atoms.colors.active1).mix(color(atoms.colors.primary), 0.2).string(),
      },
      visited: {
        color: color(atoms.colors.primary).alpha(0.8).string(),
      },
    },

    footer: {
      idle: {
        color: atoms.colors.grey2,
      },
      hover: {
        color: atoms.colors.grey2,
      },
      visited: {
        color: atoms.colors.grey2,
      },
    },
  };

  const input = {
    const: {
      radius: '40px',
      sizes: {
        extraSmall: sizes.extraSmall,
        small: sizes.small,
        medium: {
          ...sizes.medium,
          font: `16px/18px ${fonts.display}`,
        },
        large: {
          ...sizes.large,
          font: `20px/24px ${fonts.display}`,
          padding: '10px 20px',
        },
      },
    },
    basic: {
      idle: {
        border: `1px solid ${atoms.colors.divider}`,
        background: atoms.colors.input,
      },
      hover: {
        border: `1px solid ${atoms.colors.divider}`,
        background: atoms.colors.input,
      },
      focused: {
        border: `1px solid ${atoms.colors.active2}`,
        background: atoms.colors.input,
      },
      disabled: {
        border: `1px solid ${atoms.colors.hint}`,
        background: atoms.colors.input,
      },
      error: {
        border: `1px solid ${atoms.colors.error}`,
        background: atoms.colors.input,
      },
    },
  };

  const searchInput = {
    placeholder: 'поиск',
  };

  const button = {
    const: {
      sizes,
      radius: '6px',
    },
    secondaryBlack: {
      idle: {
        color: atoms.colors.black,
        background: atoms.colors.card,
        border: `1px solid ${atoms.colors.black}`,
      },

      hover: {
        color: atoms.colors.black,
        background: atoms.colors.card,
        border: `1px solid ${atoms.colors.black}`,
      },

      active: {
        color: atoms.colors.black,
        background: atoms.colors.card,
        border: `1px solid ${atoms.colors.activ1}`,
      },

      disabled: {
        color: atoms.colors.black,
        background: atoms.colors.card,
        border: `1px solid ${atoms.colors.error}`,
      },
    },
  };

  const mark = {
    padding: '0.07em',
    color: '#991C09',
    background: '#F1ECEC',
  };

  const topicContent = {
    widgetsWithMaxWidth: {
      'smartTeaser': true,
    },
    widgetsWithNegativeMobileIndent: {
      'readMore': false,
      'smartTeaser': true,
    },
  };

  const interview = {
    interitemSpacing: 20,
    question: {
      name: {
        color: atoms.colors.active1,
      },
      body: {
        fontStyle: 'italic',
      },
    },
  };

  const scooter = {
    logo: {
      width: 220,
      height: 75,
    },
    social: {
      size: 46,
      iconSize: 20,
      borderRadius: 24,
      margin: '0 0 0 14px',
    },
    menuLink: {
      ...navLink,
      font: `400 16px/22px ${fonts.display}`,
    },
  };

  const drum = {
    arrow: {
      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
      width: 60,
      height: 60,
      transform: 'translate("50%", 0)',
    },
  };

  const errorPage = {
    background: 'transparent',
    imgUrl: notFoundImg,
    imgWidth: 539,
    padding: '20px 0',
    title: {
      font: `bold 56px/56px ${fonts.display}`,
    },
    subtitle: {
      font: `36px/49px ${fonts.text}`,
    },
    card: Card,
    popular: {
      padding: '0',
    },
  };

  const pages = {
    arrow: {
      idle: {
        color: atoms.colors.hint,
      },
      hover: {
        color: atoms.colors.active1,
      },
    },
    desktop: {
      text: {
        font: `16px/1.3 ${fonts.nuance}`,
        color: atoms.colors.hint,
      },

      arrow: {
        size: '16px',
      },
    },

    mobile: {
      text: {
        font: `16px/1.3 ${fonts.nuance}`,
        color: atoms.colors.hint,
      },

      arrow: {
        size: '16px',
      },
    },
  };

  const widgetCaption = {
    desktop: {
      caption: {
        font: `16px/18px ${fonts.nuance}`,
      },
    },
    mobile: {
      caption: {
        font: `16px/18px ${fonts.nuance}`,
      },
    },
  };

  const commentsPreview = {
    desktop: {
      headline: {
        font: `bold italic 22px/1 ${fonts.display}`,
        color: atoms.colors.active1,
      },
      name: {
        font: `bold 16px/20px ${fonts.text}`,
      },
      date: {
        font: `normal 12px/16px ${fonts.nuance}`,
      },
      comment: {
        font: `normal 16px/20px ${fonts.nuance}`,
      },
    },
    mobile: {
      headline: {
        font: `bold italic 22px/1 ${fonts.display}`,
        color: atoms.colors.active1,
      },
      name: {
        font: `bold 14px/18px ${fonts.text}`,
      },
      date: {
        font: `normal 12px/16px ${fonts.nuance}`,
      },
      comment: {
        font: `normal 16px/20px ${fonts.nuance}`,
      },
    },
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.active1,
        color: atoms.colors.content,
        borderRadius: '10px',
        textTransform: 'none',
        border: 'none',
      },
    },
  };

  const personal = {
    circleCheckMark: {
      stroke: '#fff',
    },
    profileAvatar: {
      isCircular: true,
    },
  };

  const bullet = {
    basic: {
      idle: {
        background: '#C4C4C4',
      },
      active: {
        background: atoms.colors.active1,
      },
    },
  };

  const captionCredits = {
    caption: {
      font: `400 15px/18px ${fonts.display}`,
      color: atoms.colors.primary,
    },
    credits: {
      font: `400 15px/18px ${fonts.display}`,
      color: atoms.colors.grey,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `700 42px/46px ${fonts.display}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    firstLetter: {
      font: `900 73px/46px ${fonts.display2}`,
    },
    subtitle: {
      font: `400 32px/46px ${fonts.display}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.8).string(),
    },
    lead: { ...texts.lead },
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `400 16px/18px ${fonts.display}`,
      topSpacing: 30,
      size: 'small',
    },
    socialMedia: {
      type: 'fancy',
    },
    links: {
      showComma: false,
      borderBottom: 'none',
      font: `400 12px/18px ${fonts.display}`,
      color: atoms.colors.grey2,
      marginLeft: '15px',
      title: {
        font: `700 12px/18px ${fonts.display}`,
        color: atoms.colors.grey2,
      },
      hover: {
        borderBottom: `1px ${atoms.colors.grey2} solid`,
      },
    },
    block: {
      interitemSpacing: 10,
      spacing: 20,
    },
  };

  const topicTags = {
    font: `400 12px/18px ${fonts.display}`,
    showHash: false,
  };

  const topicTopline = {
    font: `14px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.3).string(),
    rubric: {
      color: atoms.colors.active1,
      border: 'none',
      borderRadius: '0',
      padding: '0',
      hover: {
        color: atoms.colors.active1,
        decoration: 'underline',
        background: 'transparent',
      },
      premiumTopic: {
        color: '#8489ff',
      },
    },
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.layout,
      padding: '20px 115px 30px',
      borderRadius: 8,
    },
    title: {
      font: `bold italic 16px/22px ${fonts.display}`,
      color: atoms.colors.hint,
      marginBottom: '20px',
      textAlign: 'center',
    },
  };

  const vikontIncut = {
    borderWidth: '0',
    padding: '0',
    font: texts.quote.font,
    color: texts.quote.color,
    textAlign: 'left',
  };

  const expert = {
    name: {
      idle: {
        font: `400 18px/27px ${fonts.nuance}`,
        margin: '0 0 3px 0',
      },
      hover: {
        font: `400 18px/27px ${fonts.nuance}`,
        margin: '0 0 3px 0',
      },
    },
    jobTitle: {
      color: atoms.colors.hint,
      font: `400 14px/18px ${fonts.nuance}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `700 italic 13px/14px ${fonts.display}`,
      textTransform: 'none',
      letterSpacing: 'normal',
      borderRadius: '6px',
    },
    avatar: {
      isAvatarCircular: true,
    },
  };

  const ads = {
    sponsored: { ...texts.body },
    urlPreviewImage: {
      retina: require('site/images/macbookPreview-2x.png'),
      common: require('site/images/macbookPreview-1x.png'),
    },
  };

  const layout = {
    sideColumnIndent: '40px',
    indents: {
      top: VERTICAL,
      right: HORISONTAL,
      bottom: VERTICAL,
      left: HORISONTAL,
    },
  };

  const colors = {
    adsColor: '#fff',
    layout: '#f9f9f9',
    content: '#f9f9f9',
  };

  const notes = {
    background: atoms.colors.yellow1,
    borderColor: atoms.colors.yellow2,
  };

  const gameCompare = {
    showComments: true,
    compareCard: {
      progressbar: {
        background: atoms.colors.black,
        progressColor: atoms.colors.black,
        strokeColor: atoms.colors.black,
      },
    },
    progressbar: {
      separationText: '/',
      iconFill: atoms.colors.card,
      textColor: atoms.colors.card,
      background: atoms.colors.active1,
      strokeColor: atoms.colors.active1,
      progressColor: atoms.colors.card,
      progressInside: true,
      font: `400 12px/17px ${fonts.text}`,
    },
    announce: {
      font: `400 18px/25px ${fonts.display}`,
    },
    compare: {
      margin: '0',
      progressType: 3,
      caption: {
        color: atoms.colors.card,
        background: atoms.colors.black,
        font: `700 12px/14px ${fonts.text}`,
      },
      credits: {
        color: atoms.colors.grey,
        font: `400 12px/15px ${fonts.display}`,
      },
      text: {
        background: '#eaebfd',
      },
      draw: {
        backgroundText: `linear-gradient(45deg, ${atoms.colors.active1} , #0710f0)`,
      },
    },
    tooltip: {
      buttonType: 'secondaryBlack',
      scheme: {
        innerColor: '#FFB800',
        outerColor: atoms.colors.black,
        titleFont: `700 18px/29px ${fonts.display}`,
        textFont: `400 16px/23px ${fonts.display}`,
      },
    },
    share: {
      color: atoms.colors.black,
      text: 'Поделитесь с друзьями:',
      font: `700 18px/21px ${fonts.display}`,
      showBorder: false,
      marginTop: '30px',
    },
    leaderBoard: {
      buttonType: 'primary',
      card: CompareCard,
      table: {
        borderColor: '#d9d9d9',
        background: atoms.colors.card,
        activeBackground: '#eaebfd',
        indent: NegativeMobile,
      },
      header: {
        color: '#5e5e5e',
        font: `400 12px/14px ${fonts.display}`,
        questionBackground: '#f3f3f3',
        questionFill: '#929292',
      },
      count: {
        color: atoms.colors.black,
        background: '#f2f2f2',
        font: `700 15px/18px ${fonts.text}`,
        activeBackground: atoms.colors.card,
        activeColor: atoms.colors.black,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: '#b3b3b3',
        defaultProgress: atoms.colors.black,
        finalProgress: atoms.colors.black,
        activeBackground: '#a4a5b1',
        font: `300 12px/17px ${fonts.nuance}`,
      },
      modalWindow: {
        title: `600 18px/30px ${fonts.text}`,
      },
      caption: `400 16px/20px ${fonts.text}`,
      ratings: `300 12px/17px ${fonts.display}`,
    },
    concidences: {
      color: atoms.colors.black,
      font: `400 18px/27px ${fonts.text}`,
      margin: '0 0 15px',
      highlight: false,
    },
    winner: {
      icon: Winner,
      background: atoms.colors.active1,
      borderRadius: '4px',
      imageTop: '30px',
      strokeColor: atoms.colors.card,
      padding: '30px',
      viewBox: '0 0 870 610',
      iconStep: 120,
      fillColor: atoms.colors.card,
    },
  };

  const reactions = {
    tiny: {
      font: `600 12px/15px ${fonts.nuance}`,
    },
    topic: {
      idle: {
        color: atoms.colors.white,
      },
      checked: {
        color: atoms.colors.white,
      },
      focus: {
        color: atoms.colors.white,
      },
      disabled: {
        color: color(atoms.colors.white).alpha(0.5).toString(),
      },
      checkedDisabled: {
        color: atoms.colors.white,
      },
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '24px 28px',
      borderRadius: '4px',
      background: atoms.colors.active1,
      border: 'none',
      boxShadow: 'none',
    },

    title: {
      color: atoms.colors.white,
      font: `700 18px/22px ${fonts.display}`,
    },
  };

  const login = {
    modal: {
      button: {
        font: `600 16px/21px ${fonts.nuance}`,
        color: atoms.colors.white,
      },
      content: {
        font: `400 16px/23px ${fonts.nuance}`,
        color: atoms.colors.primary,
      },
    },
  };

  const advTooltip = {
    font: `400 12px/15px ${fonts.nuance}`,
  };

  return deepmerge({
    fonts,
    texts,
    icons,
    ads,
    layout,
    colors,
    controls: {
      about,
      shapka,
      link,
      input,
      searchInput,
      button,
      topicContent,
      scooter,
      drum,
      bullet,
      pages,
      smartTeaser,
      widgetCaption,
      vikontReadMore,
      vikontIncut,
      commentsPreview,
      author,
      personal,
      captionCredits,
      topicHeaderTexts,
      topicFooter,
      topicTags,
      topicTopline,
      expert,
      notes,
      mark,
      interview,
      gameCompare,
      reactions,
      reactionsEmojiBar,
      login,
      advTooltip,
    },
    pages: {
      error: errorPage,
      notFound: errorPage,
    },
  }, atoms);
}
