import PropTypes from 'prop-types';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import GridFeed from 'core/components/GridFeed';
import Mjolnir from 'core/components/Mjolnir';
import Button from 'core/components/Button';
import Link from 'core/components/Link';

import BackTitle from 'site/components/BackTitle';
import TabbedPopular from 'site/components/TabbedPopular';

import { Card3Type1 } from 'site/cards/Card3';
import { Card5L, Card5S } from 'site/cards/Card5';

import styles from './index.styl';

const HeroCard = (props) => (
  <div className={styles.heroCard}>
    <Card5L {...props} />
  </div>
);


function NewsAndPopular(props) {
  const { news, popular } = props;

  return (
    <BackTitle title='Новости'>
      <div className={styles.newsAndPopular}>
        <div className={styles.news}>
          <Desktop>
            <GridFeed
              content={news}
              card={[HeroCard, Card3Type1]}
              gap='15px'
            />
          </Desktop>
          <Mobile>
            <Mjolnir
              content={news}
              heroCard={Card5S}
              card={Card3Type1}
              interitemSpacing={20}
            />
          </Mobile>

          <div className={styles.newsButton}>
            <Link to='/news' type='blank'>
              <Button size='small' >
                Все новости
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.popular}>
          <TabbedPopular content={popular} />
        </div>
      </div>
    </BackTitle>
  );
}

NewsAndPopular.propTypes = {
  news: PropTypes.array,
  popular: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsAndPopular);
