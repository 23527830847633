const content = [
  {
    id: 'spec016',
    cover: require('./images/spec016.jpg'),
    headline: 'Солнечное меню',
    announce: 'Все о том, как не остаться без полезного витамина D зимой.',
    link: 'http://sun.passion.su/',
    width: 1200,
    height: 628,
  },
  {
    id: 'spec015',
    cover: require('./images/spec015.jpg'),
    headline: 'Накорми Малоежку',
    announce: 'Pasabahce и passion.su предлагают креативный подход, как накормить малоежку в 4 шага.',
    link: 'http://pasabahce.passion.su/',
    width: 800,
    height: 420,
  },
  {
    id: 'spec014',
    cover: require('./images/spec014.png'),
    headline: 'D\'Oliva: Капсула времени',
    announce: 'Каждый возраст прекрасен! В 20 лет мы полны сил и чувствуем, что всё ещё впереди (мечтаем, начинаем свой путь), а в 40 — мы всё ещё молоды...',
    link: 'http://doliva.passion.su/',
    width: 1191,
    height: 600,
  },
  {
    id: 'spec013',
    cover: require('./images/spec013.jpg'),
    headline: 'ITLV: Готовить просто',
    announce: 'Видеоконкурс от ITLV "С любимым на испанском!" Чтобы победить, выберите и запишите на видео одну из испанских пословиц',
    link: 'http://itlv.passion.su/',
    width: 256,
    height: 256,
  },
  {
    id: 'spec012',
    cover: require('./images/spec012.jpg'),
    headline: 'Lacalut: Улыбаться чаще',
    announce: 'Мифы и факты - Выберите свою зубную пасту - Консультация онлайн - Мы делимся с вами всеми нашими знаниями',
    link: 'http://lacalut.passion.su',
    width: 1920,
    height: 1080,
  },
  {
    id: 'spec011',
    cover: require('./images/spec011.jpg'),
    headline: 'Avon: Дневник найденных возможностей',
    announce: 'Рутина офисной работы, домашние хлопоты, бесконечная учёба. Где найти время для себя?',
    link: 'http://avon.passion.su/',
    width: 1920,
    height: 1080,
  },
  {
    id: 'spec010',
    cover: require('./images/spec010.jpg'),
    headline: 'Zewa: Скоро в школу!',
    announce: 'Наши дети растут не по дням, а по часам, и это замечательно. Они взрослеют, набираются опыта и становятся все более самостоятельными...',
    link: 'http://zewa.passion.su/works/stage/5',
    width: 800,
    height: 600,
  },
  {
    id: 'spec009',
    cover: require('./images/spec009.jpg'),
    headline: 'Syoss: Что вы знаете о профессиональ-ном окрашивании?',
    announce: 'Пройдите наш тест и проверьте свои знания!',
    link: 'http://syoss.passion.su/',
    width: 375,
    height: 211,
  },
  {
    id: 'spec008',
    cover: require('./images/spec008.jpg'),
    headline: 'Tom Tailor - Знаменитые двойники',
    announce: 'Пройди викторину, найди своего двойника и выиграй модные призы!',
    link: 'http://tomtailor.passion.su/',
    width: 497,
    height: 266,
  },
  {
    id: 'spec007',
    cover: require('./images/spec007.jpg'),
    headline: 'Scarlett - Умная помощница',
    announce: 'Насыщенный ритм жизни не повод отказывать себе и близким в здоровом питании. Хотите экономить время без ущерба качеству приготовления блюд?',
    link: 'http://scarlett.passion.su/',
    width: 800,
    height: 600,
  },
  {
    id: 'spec006',
    cover: require('./images/spec006.jpg'),
    headline: 'Чистая Линия: Мое Фитолето',
    announce: 'Загрузи свое фото и выиграй сертификат на путешествие или годовой запас косметики от Чистой линии',
    link: 'http://fito.passion.su/',
    width: 1062,
    height: 841,
  },
  {
    id: 'spec005',
    cover: require('./images/spec005.png'),
    headline: 'Вкусное лето с йогуртом «Вкуснотеево»',
    announce: 'Сезон отпусков открыт! Создай и отправь открытку друзьям, коллегам!',
    link: 'http://vkusno.passion.su/',
    width: 523,
    height: 393,
  },
  {
    id: 'spec004',
    cover: require('./images/spec004.png'),
    headline: 'Fairy ProDerma: Фееричные ручки',
    announce: 'Хотите больше узнать об уходе за руками и выиграть приз за самые ухоженные ручки?',
    link: 'http://proderma.passion.su/',
    width: 989,
    height: 762,
  },
  {
    id: 'spec003',
    cover: require('./images/spec003.jpg'),
    headline: 'Lattissima+: Настроение Нового Года',
    announce: 'Для создания новогодней атмосферы, можно не только нарядить ёлку, приготовить праздничный ужин или украсить дом.',
    link: 'http://lattissima.passion.su/',
    width: 821,
    height: 394,
  },
  {
    id: 'spec002',
    cover: require('./images/spec002.png'),
    headline: 'Кулинарный гид по кухням мира',
    announce: '«Кулинария» от passion.su — ваш эксперт и продвинутый кулинарный гид в путешествиях по странам и континентам. Попробуйте мир на вкус.',
    link: 'http://foodguide.passion.su/',
    width: 600,
    height: 315,
  },
  {
    id: 'spec001',
    cover: require('./images/spec001.png'),
    headline: 'Кулинарные рецепты с детьми',
    announce: 'Начав готовить вместе с ребенком, вы обнаружите для себя много бонусов! Для того чтобы кулинарный мастер-класс показался ребенку еще заманчивее, выберите блюдо вместе. Предложите выбирать по географическому признаку, заглянув в карту мира!',
    link: 'http://cookwithpasabahce.passion.su/',
    width: 600,
    height: 315,
  },
];

export default content.map(item => {
  return {
    id: item.id,
    type: 'topic',
    attributes: {
      topic_type: 'article',
      headline: item.headline,
      published_at: '2017-11-20T12:57:00.000Z',
      link: item.link,
      announce: item.announce,
    },
    relationships: {
      rubric: {
        data: {
          id: `${item}-rubric`,
          type: 'rubric',
          attributes: {
            slug: 'slug',
            title: 'title',
            enabled: true,
            root_slug: 'root_slug',
            root_title: 'root_title',
          },
        },
      },
      image: {
        data: {
          id: `${item}-cover`,
          type: 'image',
          attributes: {
            versions: {
              original: {
                rel_url: item.cover,
                width: item.width,
                height: item.height,
              },
            },
          },
        },
      },
    },
  };
});
