import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';

import baseTheme from './base';
import baseMobileTheme from './mobile';

import darkProperties from './dark';
import lightProperties from './light';
import lightMobileProperties from './lightMobile';


const base = atoms => stylesAtomsFactory(baseTheme(atoms));

export const light = base(lightProperties);
export const dark = base(darkProperties);

export const lightMobile = stylesAtomsMobileFactory(light, baseMobileTheme(lightMobileProperties));
export const darkMobile = stylesAtomsMobileFactory(dark, baseMobileTheme(darkProperties));
